// Colors
$white: #ffffff;
$off_white: #f8f9fb;
$light_gray: #e8e8e9;
$gray: #ddd;

$blue: #2b3487;

// Background
$background: $off_white;
$background_light: $white;

// Shadow
$box_shadow: $gray;
$separator: $light_gray;

// Text
$title: $blue;

$primary: rgba(0, 25, 150, 1);
