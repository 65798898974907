@import '../variables';

.full-width {
    width: 100%;
}

.width {
    &-95 {
        width: 95%;
    }
    &-50 {
        width: 50%;
    }
}

.font-1 {
    font-size: 1rem;
}

.font-1 {
    font-size: 1.5rem;
}
.font-2 {
    font-size: 1.5rem;
}
.font-3 {
    font-size: 2rem;
}
.font-4 {
    font-size: 2.5rem;
}

.v-centered {
    vertical-align: middle;
}

.action-button {
    mat-icon {
        margin-right: 11px;
    }
    &.small-button {
        width: 80px;
    }
    &.medium-button {
        width: 140px;
    }

    margin-left: 1rem !important;

    &.mat-raised-button.mat-accent {
        background: #f4f5f9;

        border: 1px solid #cacce1;
        border-radius: 8px;
        color: #001996;
    }
}

.apply-direction {
    direction: var(--icon-direction);
}

.flex {
    display: flex;

    &.reversed {
        flex-direction: row-reverse;
    }

    &.gap {
        &10 {
            gap: 10px;
        }
        &15 {
            gap: 15px;
        }
        &20 {
            gap: 20px;
        }
    }

    &.aligned-center {
        align-items: center;
    }
    &.aligned-baseline {
        align-items: baseline;
    }

    &.justified {
        justify-content: space-between;

        &-center {
            justify-content: center;
        }
    }

    &.column {
        flex-direction: column;
    }

    &.wrapped {
        flex-wrap: wrap;
    }
}

.flex-grow {
    &1 {
        flex-grow: 1;
    }
    &2 {
        flex-grow: 2;
    }
}

.bordered {
    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-radius: 0px 0px 0px 8px;
    margin-bottom: 0.5rem;
}

.pointer {
    cursor: pointer;
}

h3 {
    font-family: 'Work Sans' !important;
}

.fit-content {
    width: fit-content;
}

.section-number {
    width: 28px;
    height: 28px;
    background: $primary;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    color: white;
}

.no-overflow {
    overflow: hidden;
}

.no-overflow-x {
    overflow-x: hidden;
}

.overflow-y {
    overflow-y: visible;
}

.no-select {
    user-select: none;
}
