@import '../variables';

* {
    font-family: 'Work Sans';
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: transparent;
}

.mat-drawer-side {
    border: 0px !important;
}
.mat-cell {
    p {
        margin: 0px;
    }
}
.mat-drawer-inner-container {
    overflow: visible !important;
    border-right: 1px solid rgba(30, 31, 36, 0.12);
}

.mat-form-field-wrapper {
    margin: 0;
    padding: 0;

    .mat-form-field-outline {
        background: white !important;
        border-radius: 8px;
    }
}

.mat-checkbox {
    &.no-margins {
        label {
            margin: 0;
        }
    }

    &-inner-container {
        .mat-checkbox-frame {
            background: white;
            border: 1px solid rgba(30, 31, 36, 0.87);
            border-radius: 1px;
        }
    }
}

mat-form-field {
    &.label-outside {
        &:not(.label-left) {
            padding-top: 20px;

            .mat-form-field {
                &-outline-gap {
                    width: 0px !important;
                    position: relative !important;

                    &:before {
                        content: var(--placeholder);
                        position: absolute !important;
                        width: 500px;
                        height: 52px;
                        line-height: 52px;
                        vertical-align: center;
                        color: rgba(0, 0, 0, 0.6);
                        left: 4px;
                    }
                }

                &-label {
                    &-wrapper {
                        overflow: visible;
                    }

                    transform: unset !important;
                    margin-top: -40px;
                    margin-left: -10px;

                    font-weight: bold;
                    color: black !important;
                }
            }
        }
    }
}

.only-icon {
    &.mat-stroked-button {
        min-width: unset;
        padding: 0px 0.25rem;
        border-width: 0px;
    }
}

.mat-raised-button {
    &.mat-primary {
        background-color: #001996;
    }
}

.mat-tab-group.mat-background-primary {
    .mat-tab-header {
        background-color: #001996;

        .mat-ink-bar {
            visibility: hidden !important;
        }

        .mat-tab-label {
            min-width: unset;
            padding: 0;
            padding: 10px 17px;
            opacity: 1;

            &-active {
                background-color: white;
                border-radius: 8px 8px 0px 0px;
                &:first-of-type {
                    border-radius: 0px 8px 0px 0px;
                }

                &:last-of-type {
                    border-radius: 8px 0px 0px 0px;
                }

                .mat-tab-label-content {
                    color: black;
                }
            }
        }
    }
}

.mat-form-field-subscript-wrapper {
    margin-top: 1.3rem;
}

.mat-radio-group {
    display: flex;

    .mat-radio-button {
        flex-grow: 1;
    }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0 30px !important;
    line-height: 32px !important;
}

mat-card {
    &.warn {
        background: #d64e4e;
        color: white;
    }
}

.mat-menu {
    &-content {
        padding: 0px !important;
        width: 226px;
    }

    &-panel {
        min-height: unset !important;
    }
}

button[mat-menu-item] {
    font-family: 'Work Sans';
    color: black;
    line-height: 35px;
    height: 35px;
    padding-left: 0px;

    .mat-icon-no-color {
        color: unset;
    }

    &:hover {
        background: $primary !important;
        color: white;
    }
}

.cdk-overlay-container {
    .select-box-tooltip {
        background-color: transparent;
        color: black;
        margin-left: -200px;
        width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.mat-dialog-container {
    overflow-x: hidden !important;
}

input:focus {
    border-color: blue;
    outline: none; /* optional: remove the default outline */
}

.mat-tab-label-active {
    border-radius: 0 8px 0 0 !important;
}

.not-scrollable {
    mat-dialog-container {
        overflow: hidden;
        padding-right: 0px;
    }
}
