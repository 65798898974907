/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

@import './styles/angular-material-overrides';

/* Custom SCSS */
@import 'variables';
@import 'src/styles/common';
@import 'app/app.component.scss';

// ---- Fonts ----

@font-face {
    font-family: 'Work Sans';
    src: url('assets/fonts/WorkSans/WorkSans-Regular.ttf') format('opentype');
    font-weight: normal;
}

@font-face {
    font-family: 'Work Sans';
    src: url('assets/fonts/WorkSans/WorkSans-Bold.ttf') format('opentype');
    font-weight: bold;
}

//@font-face {
//    font-family: 'Work Sans';
//    src: url('assets/fonts/WorkSans/WorkSans-ExtraBold.ttf') format('opentype');
//    font-weight: ;
//}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(30, 31, 36, 0.12);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #001996;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #001996;
}
