html,
body {
    height: 100%;
    background-color: #fff;
    margin: 0;
    overflow: hidden;
}

.refresh-window {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    .window-content {
        width: 650px;
        height: 250px;

        background: white;
        padding: 2rem;
        padding-top: 2.5rem;
        z-index: 3;
        border-radius: 20px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

        .icon {
            width: 82px;
            height: 82px;
        }

        .text {
            margin-left: 1.5rem;

            .header {
                padding: 1rem 0;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
            }

            .description {
                font-size: 16px;
                line-height: 19px;
            }
        }

        .reload-button {
            text-align: right;
        }
    }
}
